<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  PropType,
  reactive,
  toRefs,
  unref,
  watch,
} from 'vue';
import { isEmpty, pick } from 'lodash';

import useToasts from '../../compositions/useToasts';
import { CloseToastFunction } from '../../plugins/toasts';
import { FULLY_COMPATIBLE } from '../../utils/compat';
import { ANONYMOUS_STATUS, useProvideStatusContext, AsyncStatus } from './statusContext';

export default defineComponent({
  name: 'StatusProvider',
  compatConfig: FULLY_COMPATIBLE,
  inheritAttrs: false,
  props: {
    /**
     * Controls whether or not the skeleton is loading.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Errors to render if the content does not load. This is supposed to be a map of some errorId
     * to an Error object for historical reasons, but in practice the errorId is hardly ever used
     * and most of the times it is just `error`.
     */
    errors: {
      type: Object as PropType<AsyncStatus['errors']>,
      default: null,
    },
    /**
     * If provided, in case of an error, will render a "Retry" button
     * that calls the provided function when clicked.
     */
    retry: {
      type: Function as PropType<AsyncStatus['retry']>,
      default: null,
    },
    /**
     * Name of this provider. If given, the status provided will only be
     * visible to StatusSwitches that have the same name.
     */
    name: {
      type: [String, Symbol] as PropType<string | symbol>,
      default: ANONYMOUS_STATUS,
    },
  },
  emits: {
    /**
     * Emitted when the component mounts. This is primarily useful in cases
     * where a section of the page is conditionally rendered, and you want
     * a load call to be deferred without placing the load call and state in
     * another component.
     *
     * Note that this event is actually emitted in `onBeforeMount`, in order to
     * make sure that any related statuses are marked pending before this
     * component actually renders its contents.
     *
     * This approach to loading is somewhat experimental and subject to change.
     */
    mounted: () => true,
  },
  setup(props, { emit }) {
    const { showErrorToast } = useToasts();

    const { errorHasMultipleOutlets } = useProvideStatusContext(
      props.name as string | symbol,
      reactive(pick(toRefs(props), ['loading', 'errors', 'retry'])) as AsyncStatus,
    );

    let dismissToast: CloseToastFunction;

    const shouldShowToastError = computed(() => {
      // if there is more than one skeleton hooked to this provider, showing an error
      // message for each of them isn't that useful. instead, show a toast and leave them
      // all as loading.
      return unref(errorHasMultipleOutlets) && !isEmpty(props.errors);
    });
    watch(shouldShowToastError, (showToast, prevShowToast) => {
      if (showToast && !prevShowToast) {
        dismissToast?.(); // Just in case we already showed a toast for this provider.
        dismissToast = showErrorToast(
          `Error loading data: ${
            Object.values(props.errors || {})[0].message
          }. Please try refreshing the page.`,
          { duration: false },
        );
      }
    });

    onBeforeMount(() => {
      emit('mounted');
    });

    onUnmounted(() => {
      dismissToast?.();
    });
  },
  render() {
    return this.$slots.default?.();
  },
});
</script>
