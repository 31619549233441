export const PALETTE_COLORS = ['red', 'blue', 'orange', 'green', 'gray', 'brand'] as const;
export const BUTTON_THEMES = [
  'primary',
  'secondary',
  'tertiary',
  'link',
  'danger',
  ...PALETTE_COLORS,
  'dropdown',
  'light-gray',
] as const;
export const STANDARD_SIZES = ['sm', 'md', 'lg'] as const;
export const ICON_THEMES = ['outlined', 'filled'] as const;
export const ICON_SIZES = [12, 14, 16, 18, 20, 22, 24, 32, 36, 48, 60, 96, 128] as const;
export const FONT_AWESOME_ICON_THEMES = ['solid', 'regular', 'kit'] as const;
export const FONT_AWESOME_ANIMATION_THEMES = [
  'beat',
  'beat-fade',
  'bounce',
  'fade',
  'flip',
  'shake',
  'spin',
  'spin-pulse',
] as const;
